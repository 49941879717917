<template>
  <div>
   <div class="top-menu has-background-light p-4">

    <h3>{{ space?.title }}</h3>
    <b-button 
      type="is-primary"
      class="print-btn"
      :disabled="!downloable"
      @click="generatePDF()">
      <d-icon icon="FaDownload"/>
      {{ $t('system.download') }}
    </b-button>

    </div>

  <div class="scrolleable">
    <div id="printable" class="printable">
      <canvas ref="canvas" id="canvas" :width="width" :height="height"></canvas>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import QRCode from 'qrcode-js-package/qrcode.js'
import { jsPDF } from "jspdf";

export default {  
  components: {
    
  },
  data() {
    return {
      space: null,
      spaceURL: null,
      host: window.location.host,
      canvas: null,
      width: 1748,
      height: 2480,
      appLogo: null,
      qrCanvas: null,
      qrCode: null,
      downloable: false
    };
  },
  computed: {
   
  },
  methods: {
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    },
    renderError(message){
      const ctx = this.canvas.getContext('2d');
      ctx.font = `50pt 'Noto Sans', Arial, sans-serif`;
      ctx.textAlign = "center";
      ctx.fillText(message, this.width / 2, 200);
    },
    async renderCanvas() {
      const ctx = this.canvas.getContext('2d');
      const gap = 140;
      let elemY = 210;

      // Background
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, this.width, this.height);

      // AppLogo
      const img = this.appLogo;
      const imgHeight = 355;
      const scale = imgHeight / img.height;
      const imgWidth = img.width * scale;
      ctx.drawImage(img, (this.width - imgWidth)/ 2, elemY, imgWidth, imgHeight); 
      elemY += imgHeight + gap

      // QRCode
      const qrSize = 1000;
      ctx.drawImage(this.qrCode, (this.width - qrSize)/ 2, elemY, qrSize, qrSize); 
      elemY += qrSize + gap

      // Host
      const fontFamily = "'Noto Sans', Arial, sans-serif";
      ctx.font = `bold 65pt ${fontFamily}`;
      ctx.textAlign = "center";
      ctx.fillStyle = "black";
      ctx.fillText(this.host, this.width / 2, elemY);

      // Footer
      ctx.fillStyle = this.space.section.color;
      ctx.fillRect(0, this.height - 500, this.width, this.height);

      if(this.space.alphanumeric){
        ctx.textAlign = "right";
        ctx.fillStyle = "white";
        ctx.font = `bold 100pt ${fontFamily}`;
        ctx.fillText(this.space.alphanumeric.toUpperCase(), this.width - 140, this.height - 210);
      }

      // Border
      ctx.lineWidth = 3;
      ctx.strokeStyle = "black";
      ctx.strokeRect(0, 0, this.width, this.height);

      this.downloable = true;

    },
    generatePDF() {
      const pdf = new jsPDF({
        format: 'a5'
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imageData = this.canvas.toDataURL('image/png');

      pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      pdf.save(`qr-${this.space.slug}.pdf`);
    },
  },
  async mounted() {
    this.canvas = this.$refs.canvas
    
    const param = this.$route.params.slug;
    
    let query = `${process.env.VUE_APP_API_BASE}/api/spaces?where[or][0][slug][equals]=${param}&where[or][1][id][equals]=${param}`;

    const res = await axios.get(query);

    if(res.data.docs.length != 0) {
      this.space = res.data.docs[0]
      this.spaceURL = window.location.origin + "/" + this.space.slug

      this.appLogo = await this.loadImage(this.$store.state.setup.appLogo ? this.$store.state.setup.appLogo.url : '/default-header.png');

      const qrElement = document.createElement('div');

      await new QRCode(qrElement, {
          text: this.spaceURL,
          width: 256,
          height: 256,
          colorDark : 'black',
          colorLight : 'white',
          correctLevel : QRCode.CorrectLevel["L"]
        });

      this.qrCode = await this.loadImage(qrElement.firstChild.toDataURL());
      this.renderCanvas()
      
    } else {
    
      this.renderError(this.$t('system.spaceNotFound'))
    }

    this.$store.commit("StopLoading");
        
  }
};
</script>

<style lang="scss" scoped>
.top-menu  {
  display: flex;
  align-items: center;

  h3 {
    flex: 1;
    font-weight: bold;
  }
}
.scrolleable {
  overflow: auto;
}

.printable {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc; 
  box-sizing: border-box;
  margin: 1em;
}
</style>